/* Header.css */

/* Navbar general styling */
.navbar {
  height: 100px;
  background-color: #26ab2c; /* Vibrant green */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
}

/* Navbar links alignment */
.navbar-collapse {
  justify-content: flex-end; /* Align links to the right */
}

.navbar-nav .nav-link {
  font-size: 1.2rem;
  color: #ffffff;
  margin-left: 20px; /* Add space between links */
  transition: color 0.3s ease;
}

.navbar-nav .nav-link:hover {
  color: #ffd700; /* Gold on hover */
}

/* Burger button styling */
.navbar-burger svg {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.navbar-burger svg:hover {
  background-color: #ffd700; /* Gold on hover */
}

/* Mobile menu adjustments */
.navbar-menu {
  background-color: #ffffff;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
}

.navbar-menu .nav-link {
  font-size: 1.5rem;
  color: #333333;
  margin-bottom: 15px;
}

.navbar-menu .nav-link:hover {
  color: #26ab2c;
}

/* Mobile close button */
.navbar-close {
  color: #111827;
  cursor: pointer;
}

@media (max-width: 991px) {
  .navbar {
    padding: 10px 15px;
  }

  .navbar-nav .nav-link {
    margin-left: 0;
  }
}

  .footer-link:hover {
    color:rgb(15, 125, 222) !important;
  }  
  .active{
    color:rgb(13, 124, 176) !important;
  }
  .card {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 450;
    font-style: normal;
  }
  
  .curved{
  border-bottom-left-radius: 50% 30px; /* Set border radius for bottom-right corner */
  border-bottom-right-radius: 50% 50px; /* Set border radius for bottom-right corner */
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  }
  /* Target the scrollbar */
  ::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  
  }
  
  /* Track (the area behind the scrollbar) */
  ::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
  }
  
  /* Handle (the draggable part of the scrollbar) */
  ::-webkit-scrollbar-thumb {
  background: #888; /* Color of the handle */
  border-top-left-radius:5px;
  border-bottom-left-radius:5px;
  border-bottom-right-radius:5px;
  border-top-right-radius:5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the handle on hover */
  }