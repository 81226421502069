.image-aboutus-banner {
  background: rgba(0, 0, 0, 0.6); /* Dim black with 60% opacity */
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; /* White text for contrast */
}

.image-aboutus-banner h1 {
  font-size: 2.5rem;
  color: white; /* White text for readability */
  font-weight: bold;
}

.image-aboutus-banner p {
  font-size: 1.2rem;
  color: #f8f9fa; /* Slightly lighter text */
}

.btn-hover-green {
  transition: background-color 0.3s, border-color 0.3s;
}

.btn-hover-green:hover {
  background-color: #28a745 !important; /* Green color on hover */
  border-color: #28a745 !important;
}


/* General styles for the sidebar */
.ads {
  height: 50px;
  background-color: #f8f9fa; /* Light background color for the ad space */
  margin-bottom: 1rem;
}

.text {
  text-align: center;
  background-color: #e9ecef; /* Light gray background for the heading area */
  padding: 0.5rem 0;
  border-radius: 5px;
}

.text h3 {
  font-size: 1.5rem; /* Heading size */
  color: black; /* Black font color for the heading */
  margin: 0;
}

.tools-list {
  background-color: #ffffff; /* White background for tools list */
  border: 1px solid #dee2e6; /* Subtle border for the tools list */
  border-radius: 5px;
  padding: 1rem;
  margin-top: 1rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Shadow for depth */
}

.tools-list ul {
  list-style: none; /* Remove bullet points */
  padding: 0;
  margin: 0;
}

.tools-list li {
  margin: 0.5rem 0; /* Add spacing between list items */
}

.tools-list a {
  text-decoration: none; /* Remove underline from links */
  color: #007bff; /* Bootstrap blue color for links */
  font-weight: 500; /* Medium font weight for better readability */
}

.tools-list a:hover {
  color: #0056b3; /* Darker blue on hover */
  text-decoration: underline; /* Underline the link on hover */
}

.ads{
  height: 300px;
}

.custom-container {
  background-color: #f9f9f9; /* Light gray background */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Internal spacing */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}


